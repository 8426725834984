// projectsData.js

import greenpoint from '../assets/gplogo.png'
import softalmology from '../assets/softalmologylogo.png'
import ldg from '../assets/LDGLogo.png'
import gabbygamiz from '../assets/gglogo.png'
import habitat from '../assets/logohabitat.png'
import lowt from '../assets/lowtlogo.png'

export const projects = [
    {
        title: 'GreenPoint',
        description: 'GreenPoint is an advanced farm management software designed to streamline and optimize the operations of modern farms. With comprehensive features such as crop tracking, inventory management, equipment maintenance scheduling, and analytics reporting, GreenPoint empowers farmers to make informed decisions, maximize crop yields, and ensure efficient resource utilization. By integrating cutting-edge technology, GreenPoint revolutionizes the way agricultural businesses operate and thrive in today\'s dynamic farming landscape.',
        icon: greenpoint,
        features: [
            'Streamline farm management processes',
            'Track crop growth and yields effectively',
            'Manage inventory and supplies with ease',
            'Schedule equipment maintenance and tasks',
            'Generate detailed reports and analytics',
            'Optimize resource utilization and planning',
            // ... more features
        ],
      //  url: 'https://greenpoint.ag/',
    },
    {
        title: 'Softalmology',
        description: 'Softalmology is a comprehensive Electronic Medical Records (EMR) system tailored specifically for ophthalmologists. This advanced software solution combines patient appointment scheduling, medical record management, treatment planning, and integrated medical device interfaces to create a seamless experience for both practitioners and patients. With Softalmology, ophthalmologists can efficiently manage their practice, offer superior patient care, and ensure accurate record-keeping to enhance their medical practice.',
        icon: softalmology,
        features: [
            'Efficiently manage patient appointments',
            'Store and access electronic medical records',
            'Prescribe treatments and medications accurately',
            'Integrate with ophthalmic diagnostic devices',
            'Ensure HIPAA-compliant data security',
            'Enhance patient care and satisfaction',
            // ... more features
        ],
        url: 'https://softalmology.com/',
    },
    {
        title: 'Listing Description Generator',
        description: 'The Listing Description Generator is an AI-powered tool designed to revolutionize the real estate industry. This innovative solution leverages artificial intelligence to automatically generate compelling and tailored property descriptions for real estate listings. By analyzing property features, neighborhood characteristics, and market trends, the Listing Description Generator creates engaging content that captures potential buyers\' attention and enhances property visibility. With this tool, real estate professionals can save time, improve listings, and drive more effective property marketing strategies.',
        icon: ldg,
        features: [
            'Automatically generate property descriptions',
            'Customize content based on property features',
            'Enhance SEO and listing visibility',
            'Save time in content creation for listings',
            'Improve engagement and buyer interest',
            'Adapt to evolving market trends and demands',
            // ... more features
        ],
        url: 'https://listingdescriptiongenerator.com/',
    },
    {
        title: 'GabbyGamiz',
        description: 'GabbyGamiz is a comprehensive software solution designed to streamline bakery operations and manage pastry recipes efficiently. With features such as ingredient inventory tracking, recipe organization, production scheduling, and shopping list generation, GabbyGamiz empowers bakers to optimize their workflows and deliver delicious treats to their customers. By leveraging technology, GabbyGamiz ensures precise ingredient management and simplifies the process of creating delectable pastries.',
        icon: gabbygamiz,
        features: [
            'Efficiently manage bakery inventory',
            'Organize and access pastry recipes',
            'Schedule production and baking tasks',
            'Generate shopping lists for ingredients',
            'Ensure ingredient freshness and accuracy',
            'Enhance bakery productivity and quality',
            // ... more features
        ],
    },
    {
        title: 'Habit-at',
        description: 'Habit-at is a user-friendly app designed to help individuals build and maintain positive habits in their daily lives. With intuitive habit tracking, reminder notifications, and progress visualization, Habit-at empowers users to stay accountable and achieve their personal goals. By offering insights into habit streaks and patterns, Habit-at provides motivation and support for lasting positive changes, making it an essential tool for personal growth and self-improvement.',
        icon: habitat,
        features: [
            'Set and track personal habits and goals',
            'Receive customizable reminder notifications',
            'View habit streaks and progress history',
            'Analyze habit patterns and trends',
            'Stay motivated and focused on positive changes',
            'Create a personalized habit-building journey',
            // ... more features
        ],
    },
    {
        title: 'Low-T',
        description: 'Low-T is a secure online marketplace that connects buyers and sellers of medical items, providing a convenient platform for the sale and purchase of medical equipment, supplies, and devices. With features such as verified seller profiles, secure transactions, quality assurance, and customer support, Low-T ensures a seamless experience for medical professionals and institutions looking to source essential medical items. By fostering transparency and trust, Low-T contributes to the efficient flow of medical supplies in the healthcare ecosystem.',
        icon: lowt,
        features: [
            'Connect medical buyers and sellers',
            'Ensure secure and transparent transactions',
            'Verify seller credentials and quality standards',
            'Offer a wide range of medical items and products',
            'Provide customer support and dispute resolution',
            'Streamline procurement processes for healthcare',
            // ... more features
        ],
    },
    // ... more projects
];
