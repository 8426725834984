import './App.css';
import Home from './pages/Home';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Profile from './components/Profile';
import LDGPrivacyPolicy from './views/ldgPrivacyPolicy';
import SoftalmologyPrivacyPolicy from './views/SoftalmologyPrivacyPolicy';
import HabitatPrivacyPolicy from './views/habitatPrivacyPolicy';
import CasaCristinaPrivacyPolicy from './views/CasaCristinaPrivacyPolicy';
import GreenPointPrivacyPolicy from './views/GreenPointPrivacyPolicy';
import LowTPrivacyPolicy from './views/LowTPrivacyPolicy';
import NSBJJPrivacyPolicy from './views/NSBJJPrivacyPolicy';
import ContactForm from './components/Contact';


const Layout = () => {
  return (
    <div>
      <ScrollRestoration />
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "profile/:id", element: <Profile/> },
      {path : "ldg-privacy-policy", element: <LDGPrivacyPolicy/>},
      {path : "softalmology-privacy-policy", element: <SoftalmologyPrivacyPolicy/>},
      {path : "habitat-privacy-policy", element: <HabitatPrivacyPolicy/>},
      {path : "greenpoint-privacy-policy", element: <GreenPointPrivacyPolicy/>},
      {path : "casacristina-privacy-policy", element: <CasaCristinaPrivacyPolicy/>},
      {path : "lowt-privacy-policy", element: <LowTPrivacyPolicy/>},
      {path : "nsbjj-privacy-policy", element: <NSBJJPrivacyPolicy/>},
      {
        path: "contact/:projectId/:questionId",
        element: <ContactForm />,
      }


    ],
  },
]);



function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

