// ProjectsView.js

import React, { useState } from 'react';
import { projects } from '../data/projects';


const ProjectsView = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = (index) => {
        if (selectedProject === index) {
            return;
        } else {
            setSelectedProject(index);
        }
    };

    return (
        <div className="container mx-auto py-10" id="projects-section">
            <h2 className="text-4xl font-bold mb-6 text-center text-slate-800">Our Projects</h2>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-full px-4 mb-8">
                    <div className="project-card bg-white shadow-md p-6">
                        <div className={`flex flex-col md:flex-row md:justify-around`} >
                            {projects.map((project, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col items-center md:items-center md:w-/3 mb-4  hover:shadow-md hover:translate-y-1 m-2 ${selectedProject === index ? 'active' : ''
                                        } `}
                                    onClick={() => handleProjectClick(index)}
                                >
                                    <img
                                        src={project.icon}
                                        alt={`${project.title} Icon`}
                                        className={`w-10 h-10 mr-3 `}
                                    />
                                    <h3
                                        className={`text-xl font-bold text-slate-800 text-center md:text-center`}
                                    >
                                        {project.title}
                                    </h3>
                                </div>
                            ))}
                        </div>
                        {selectedProject !== null && (
                            <div className="project-details text-slate-600 mt-4 flex flex-col md:flex-col justify-center pl-10 pr-10">
                                <div className="md:w-1/1 text-justify md:text-justify mb-4 md:mb-0">
                                    <p className="text-slate-600 font-normal text-lg">{projects[selectedProject].description}</p>
                                </div>
                                <div className="md:w-1/1 mt-10">
                                    <h4 className="text-2xl font-semibold mb-2 text-left text-slate-800">Features:</h4>
                                    <ul className="list-disc ml-6 dots text-left mt-5 grid grid-rows-3 md:grid-cols-2 gap-4">
                                        {projects[selectedProject].features.map((feature, featureIndex) => (
                                            <div className="text-slate-800 font-normal text-xl mb-1">
                                                <span className="mr-2">&#9632;</span>
                                                {feature}
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                                {projects[selectedProject].url && (
                                <div className="mt-10 text-center">
                                    <a
                                        href={projects[selectedProject].url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-block px-4 py-2 bg-slate-700 rounded-lg text-white hover:bg-gray-800 font-semibold"
                                    >
                                        Read more
                                    </a>
                                </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsView;
