// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// Import your Firebase app and the storage service
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

// Get a reference to the storage service, which is used to create references in your storage bucket
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCCOpfToba5Gqd7yrbA0BKAASEbtNsjedY",
  authDomain: "coding-solved.firebaseapp.com",
  databaseURL: "https://coding-solved-default-rtdb.firebaseio.com",
  projectId: "coding-solved",
  storageBucket: "coding-solved.appspot.com",
  messagingSenderId: "59093349347",
  appId: "1:59093349347:web:cbce226a4b62597cbe26d6",
  measurementId: "G-NJ76H5JKFL"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const storage = getStorage(app);
export { storage, storageRef, uploadBytes, getDownloadURL };